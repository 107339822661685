@import '../../assets/styles/colors';

.option {
  &__wrapper {
    position: relative;
    display: block;
    width: 100%;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 12px;
    color: $white;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    box-shadow: 0 3px 6px #00000029;
    background-color: $primary_2;
    border-radius: 6px;
  }

  &__field {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    opacity: 0;
    z-index: 1;
    margin: 0;
    cursor: pointer;

    &:checked {
      + .option__label {
        background-color: darken($primary_2, 10%);
      }
    }
  }

  &--white {
    .option{
      &__label {
        border-radius: 5px;
        border: 1px solid $primary_2;
        background-color: $white;
        color: $primary_2
      }

      &__field {
        &:checked {
          + .option__label {
            background-color: $primary_2;
            color: $white;
          }
        }
      }
    }
  }
}
