$primary_1: #238366;
$primary_2: #3C7AAD;
$white: #FFFFFF;
$black: #605E5E;
$blue: #B6CFE4;
$gray_1: #757575;
$gray_2: #707070;
$gray_3: #DBE7F2;
$gray_4: #DCDCDC;
$green: #52DB84;
$orange: #FF7700;
$red: #F23131;
$yellow: #F2C531;
