@import '../../assets/styles/colors';

.ok {
  width: 206px;
  height: 185px;

  &--gray {
    fill: $gray_3;
  }

  &--green {
    fill: $green;
  }

  &--red {
    fill: $red;
  }

  &--yellow {
    fill: $yellow;
  }

  &--blue {
    fill: $blue;
  }
}
