@import '../../assets/styles/colors';

.warning {
  width: 206px;
  height: 185px;

  &--gray {
    fill: $gray_3;
  }

  &--green {
    fill: $green;
  }

  &--red {
    fill: $red;
  }

  &--yellow {
    fill: $yellow;
  }
  &--orange {
    fill: $orange;
  }

}
