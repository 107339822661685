@import '../../assets/styles/colors';

.range {
  &__label {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0;
    font-weight: bold;
    margin-bottom: 12px;
  }

  &__track {
    display: block;
    position: relative;
    width: 100%;
    height: 38px;

    &:after {
      position: absolute;
      top: 14px;
      left: 30px;
      width: calc(100% - 60px);
      height: 2px;
      background-color: $primary_2;
      content: '';
    }
  }

  &__options {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    margin-top: -34px;
  }

  &__option {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    -webkit-appearance: none;
    border: none;
    background: none;
    height: 39px;
    padding: 0;
    font-size: 14px;
    line-height: 13px;
    text-transform: uppercase;
    color: $gray_2;
    cursor: pointer;

    &:before {
      opacity: 0;
      width: 18px;
      height: 18px;
      border: 2px solid $primary_1;
      background-color: $white;
      content: '';
      border-radius: 50%;
    }

    &:focus {
      outline: none;
    }

    &.active {
      &:before {
        opacity: 1;
      }
    }
  }
}
