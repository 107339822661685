@import '../../assets/styles/colors';

.checkbox {
  &__wrapper {
    display: flex;
    min-height: 29px;
  }

  &__field {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 1;
    opacity: 0;
    margin: 0;
    padding: 0;

    &__wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &__btn {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 5px;
    border: 1px solid $gray_1;
    margin-right: 9px;

    &:after {
      position: absolute;
      top: 2px;
      left: 2px;
      width: 10px;
      height: 10px;
      content: '';
      border-radius: 3px;
      background-color: $primary_2;
      display: none;
    }
  }

  &__error {
    border: 1px solid $red;
  }

  &__description {
    width: calc(100% - 26px);
    color: $gray_2;
    font-size: 12px;
    letter-spacing: 0;

    &__wrapper {
      display: flex;
    }

    span {
      position: relative;
      color: $primary_2;
      text-decoration: underline;
      cursor: pointer;
      z-index: 2;
    }
  }

  &__field {
    &:checked + .checkbox__description__wrapper {
      .checkbox__btn {
        border-color: $primary_2;
        &:after {
          display: block;
        }
      }
    }
  }

  &__error_description {
    font-size: 10px;
    line-height: 13px;
    margin-left: 24px;
    letter-spacing: 0;
    color: #F23131;
  }

  &.big {
    .checkbox {
      &__description {
        width: calc(100% - 31px);
        color: $primary_2;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 21px;
      }
    }
  }
}
