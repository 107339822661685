@import '../../../../assets/styles/mixins';

.registration {
  &__step--3 {
    @include viewBaseStyles(minmax(auto, 202px));

    .banner {
      grid-area: banner;
    }

    .container {
      grid-area: container;
      align-items: flex-start;

      h3 {
        margin: 33px 0 36px;
      }

      .button {
        margin: auto 0 33px auto;
      }
    }
  }
}
