.back-btn {
  position: absolute !important;
  top: 0;
  left: 21px;
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 4 !important;

  &:focus {
    outline: none;
  }

  .button {
    padding: 7px 11px;

    &__icon {
      margin-right: 4px;
    }
  }
}
