@import '../../../../assets/styles/mixins';

.registration{
  &__step--5 {
    @include viewBaseStyles(149px);

    .banner {
      grid-area: banner;
    }

    .container {
      grid-area: container;
      align-items: flex-start;

      h3 {
        margin: 46px 0 36px;
      }

      .fieldset {
        margin-bottom: 7px;
      }

      .button {
        margin: auto 0 33px auto;
      }
    }
  }
}
