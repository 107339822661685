@import '../../assets/styles/mixins';

.view__regulations {
  @include viewBaseStyles();

  .banner {
    grid-area: banner;
  }

  .container {
    grid-area: container;
    display: grid;
    grid-template-areas: "subtitle" "title" "content";
    grid-template-rows: 40px 52px 1fr;
    max-width: 320px;

    .p2 {
      grid-area: subtitle;
      padding-top: 27px;
    }

    h4 {
      grid-area: title;
      padding-bottom: 38px;
    }

    .content {
      grid-area: content;
      padding-bottom: 30px;
    }
  }
}
