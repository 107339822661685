.instruction {
  &__step {
    display: grid;
    grid-column-gap: 13px;
    grid-template-columns: 79px 1fr;

    + .instruction__step {
      margin-top: 34px;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-self: center;

    img {
      max-width: 79px;
      height: auto;
      max-height: 40px;

      &.icon-1 {

      }
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 19px 1fr;
  }
}
