@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.view__regulations {
  @include viewBaseStyles();

  .banner {
    grid-area: banner;
  }

  .container {
    display: grid;
    grid-template-areas: "subtitle" "title" "content";
    grid-template-rows: 40px 52px 1fr;
    grid-area: container;

    .p2 {
      grid-area: subtitle;
      padding-top: 27px;
    }

    h4 {
      grid-area: title;
      padding-bottom: 38px;
    }

    .content {
      grid-area: content;

      padding-bottom: 50px;
      color: $black;

      p {
        padding-bottom: 1em;
      }

      ul {
        padding-inline-start: 15px;
        font-weight: unset;
      }
    }
  }
}
