@import '../../../../assets/styles/mixins';
.registration {
  &__step--7 {
    @include viewBaseStyles(251px);

    .banner {
      grid-area: banner;
    }

    .container {
      grid-area: container;
      align-items: flex-start;

      h3 {
        text-align: left;
        margin: 45px 0 21px;
      }

      .fieldset {
        margin-bottom: 43px;
      }

      .button {
        margin: auto 0 33px auto;
      }
    }
  }
}
