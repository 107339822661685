@import '../../assets/styles/colors';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 286px;
  padding: 0 12px;
  line-height: 16px;
  font-size: 16px;
  letter-spacing: 0;
  -webkit-appearance: none;
  box-shadow: 0 3px 6px #00000029;
  border-style: none;
  border-radius: 6px;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.6;
  }

  &--primary {
    background-color: $primary_2;
    color: $white;
  }

  &--outline {
    background-color: $white;
    color: $primary_2;
    border: 1px solid $primary_2;
  }

  &--white {
    background-color: $white;
    color: $primary_2;
  }

  &--secondary {
    background-color: $white;
    border: 1px solid $primary_1;
  }

  &--blank {
    background: none;
    color: #DCDCDC;
    box-shadow: none;
  }

  &--black {
    background-color: $black;
    color: $white;
  }

  &--success {
    background-color: $primary_1;
    color: $white;
  }

  &--success-blank {
    background-color: $white;
    border: 1px solid $primary_1;
    color: $black;
  }

  &--gray {
    background-color: $gray_4;
    color: $black;
  }

  &--small {
    min-height: 29px;
  }

  &--normal {
    height: 42px;
  }

  &--size {
    &--small {
      min-width: 74px;
      max-width: min-content;
      padding: 5px 10px;
      text-align: left;
    }

    &--medium {
      width: 50%;
    }
  }

  &__icon {
    width: 16px;
    height: 15px;
    margin-left: 12px;
  }

  &--icon-left {
    flex-direction: row-reverse;

    .button__icon {
      margin-left: 0;
      margin-right: 12px;
    }
  }
}
