@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.view__install-app-instruction {
  @include viewBaseStyles();

  .banner {
    grid-area: banner;
  }

  .container {
    display: grid;
    grid-template-areas: 'brand' 'content' 'buttons';
    grid-template-rows: 165px min-content auto;
    grid-area: container;
    align-items: baseline;
    padding: 58px 0 20px;

    .brand {
      grid-area: brand;
      margin: 0;
    }
  }
}
