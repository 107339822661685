@import '../../assets/styles/colors';

.banner {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  border-radius: 0 0 83px 0;
  overflow: hidden;

  > * {
    position: relative;
    z-index: 2;
  }

  .brand {
    margin: 0;
  }
}
