@import '../../assets/styles/colors';

.view__data {
  padding-top: 75px;
  padding-bottom: 52px;

  .banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 75px;
    z-index: 2;
  }

  .container {
    &__content {
      display: grid;
      grid-template-areas: "title date" "form form";

      .title {
        grid-area: title;
      }

      .date {
        grid-area: date;
        justify-self: end;
      }

      .form {
        grid-area: form;

        h4 {
          margin-bottom: 7px;
        }

        .fieldset {
          + h4 {
            margin-top: 30px
          }
        }
      }
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    z-index: 2;

    .fieldset {
      padding: 18px 0 24px;
      border-top: 1px solid $gray_4;
    }
  }
}
