@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.view__risk-test {
  @include viewBaseStyles();

  .banner {
    grid-area: banner;
  }

  .container {
    display: grid;
    grid-template-areas: "title" "today" "line" "list";
    grid-template-rows: 65px auto 25px 1fr;
    grid-area: container;

    .h1 {
      grid-area: title;
    }

    .line {
      grid-area: line;
      align-self: center;

      &:after {
        display: block;
        content: '';
        height: 1px;
        width: 100%;
        background-color: $gray_4;
      }
    }

    .today {
      grid-area: today;
    }

    .fieldset {
      grid-area: list;
      align-self: baseline;

      .button {
        position: relative;
        :first-child {
          padding-right: 10px;
        }
      }
    }
  }
}
