@import '../../assets/styles/colors';

@mixin smallPlaceholder() {
  color: $black;
  font-size: 10px;
  line-height: 13px;
};

.input {
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__field {
    height: 28px;
    border-style: solid;
    border-width: 0 0 2px;
    border-color: $primary_2;
    font-size: 18px;
    line-height: 24px;
    padding: 2px;
    color: $black;
    letter-spacing: 0;
    -webkit-appearance: none;
    border-radius: 0;

    &:focus {
      outline: none;
    }

    &--small {
      width: 80%;

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @include smallPlaceholder;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        @include smallPlaceholder;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        @include smallPlaceholder;
      }
      &:-moz-placeholder { /* Firefox 18- */
        @include smallPlaceholder;
      }
    }
  }

  &__label {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    margin-bottom: 9px;
  }

  &__description {
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0;
    color: $black;
  }

  &__error {
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0;
    color: $red;
  }
}
