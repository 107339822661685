@import '../../assets/styles/colors';

.menu__button {
  position: absolute !important;
  top: 10px;
  right: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 32px;
  height: 30px;
  cursor: pointer;
  -webkit-appearance: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;

  &:focus {
    outline: none;
  }

  &__line {
    display: block;
    width: 32px;
    height: 4px;
    background-color: $white;

    @media screen and (min-width: 900px){
      box-shadow: 2px 2px 3px $black;
    }
  }
}
