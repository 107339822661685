.brand {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 19px auto 7px;

  &__sign {
    width: 64px;
    height: 64px;
    margin-bottom: 6px;
  }

  &__text {
    width: 142px;
    height: 26px;
    margin-bottom: 12px;
  }

  &__content {
    text-align: center;
  }

  &--white {
    justify-content: center;
    height: 100%;

    .brand {
      &__text {
        margin-bottom: 0;
      }
    }
  }

  &--small {
    .brand {
      &__sign {
        width: 41px;
        height: 41px;
        margin-bottom: 4px;
      }

      &__text {
        width: 93px;
        height: 17px;
      }
    }
  }
}
