@import '../../assets/styles/colors';

.app {
  position: relative;
  min-width: 320px;
  overflow-x: hidden;

  &__inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    transition: 200ms transform linear;
  }

  &.menu-visible {
    .app__inner {
      transform: translate(-259px, 0);
    }
  }
}

body {
  * {
    box-sizing: border-box;
  }

  h2 {
    color: $black;
    font-size: 28px;
    letter-spacing: 0;
    line-height: 37px;
  }

  h3 {
    color: $black;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
  }

  h4 {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    color: $primary_2;
    margin: 0;

    &.medium {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0;
    }

    &.medium-2 {
      font-size: 16px;
      line-height: 21px;
    }

    &.big {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0;
    }
  }

  h5 {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    color: $primary_1;
    margin: 0;

    &.small {
      font-size: 14px;
      color: $primary_1;
      line-height: 19px;
    }

    &.medium {
      font-size: 20px;
      line-height: 21px;
    }

    &.big {
      font-size: 22px;
      line-height: 29px;
    }
  }

  h6 {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    color: $primary_2;
    font-weight: normal;
  }

  p {
    color: $black;
    letter-spacing: 0;
    margin: 0;

    + .text-list {
      margin-top: 8px;
    }

    &.smaller {
      font-size: 10px;
      line-height: 13px;
    }

    &.small {
      font-size: 12px;
      line-height: 16px;
    }

    &.medium {
      font-size: 14px;
      line-height: 19px;
    }

    &.medium-2 {
      font-size: 16px;
      line-height: 21px;
    }

    &.big {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .primary-1 {
    color: $primary_1;
  }

  .primary-2 {
    color: $primary_2;
  }

  .text-bold {
    font-weight: bold;
  }

  .text-center {
    text-align: center;
  }

  .text-warning {
    color: $orange;
  }

  .text-error {
    color: $red;
  }

  .view {
    width: 100%;
  }

  .text-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      padding-left: 12px;
      margin-bottom: 8px;

      &:before {
        position: absolute;
        top: 2px;
        left: 0;
        content: '';
        background-color: $primary_2;
        border-radius: 50%;
        width: 5px;
        height: 5px;
      }

      p {
        margin: 0;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}
