.fieldset {
  width: 100%;

  .input {
    + .checkbox {
      margin-top: 12px;
    }
  }

  .checkbox {
    + .checkbox {
      margin-top: 2px;
    }

    &.big + .checkbox {
      margin-top: 7px;
    }
  }

  .option {
    + .option {
      margin-top: 24px;
    }
  }

  .button {
    + .button {
      margin-top: 10px;
    }
  }

  .range {
    + .range {
      margin-top: 17px;
    }
  }

  &--horizontal {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    > * {
      width: calc(50% - 10px) !important;

      &:nth-child(2) {
        margin-top: 0 !important;
      }
    }

    .option {
      + .option {
        margin-top: 12px;
      }
    }
  }
}
