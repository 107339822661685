@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.view__information {
  @include viewBaseStyles(100px);

  .banner {
    grid-area: banner;
  }

  .container {
    display: grid;
    grid-template-areas: 'content' 'buttons';
    grid-template-rows: auto 1fr;
    grid-area: container;
    align-items: baseline;
    padding: 30px 0;

    .user {
      grid-area: user;
      align-self: end;
      justify-self: start;
      display: flex;
      align-items: center;

      img {
        margin-right: 15px;
      }
    }

    .content {
      grid-area: content;
      align-self: start;

      .p1 {
        margin-bottom: 22px;
      }
    }

    .fieldset {
      grid-area: buttons;
      align-self: end;
      padding-top: 18px;
      margin-top: 18px;
      border-top: 1px solid $gray_4;
    }
  }
}
