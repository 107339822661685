@import '../../assets/styles/mixins';

.view__user-data {
  @include viewBaseStyles();

  .banner {
    grid-area: banner;
  }

  .container {
    display: grid;
    grid-template-areas: "title" "content";
    grid-template-rows: 71px min-content;
    grid-area: container;

    h4 {
      grid-area: title;
      padding-bottom: 20px;
    }

    .content {
      grid-area: content;
      align-self: start;
    }

    .fieldset {
      padding: 53px 0;
    }
  }
}

.user-data {
  &__modal-content {
    h4 {
      margin-bottom: 6px;
    }

    p {
      margin-bottom: 12px;
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .button {
        width: 76px;
      }
    }
  }

  &__modal-confirmation {
    max-width: 192px;
    margin: 0 auto;

    h4 {
      margin-bottom: 17px;
    }
  }
}
