@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/mixins';

.view__registration-summary {
  @include viewBaseStyles(100px);

  .banner {
    grid-area: banner;

    .reset-registration {
      position: absolute;
      top: 28px;
      left: 18px;
      z-index: 10;

      .button {
        padding: 3px;

        &__icon {
          margin-right: 3px;
        }
      }
    }
  }

  .container {
    display: grid;
    grid-template-areas: 'user' 'content';
    grid-template-rows: 48px 1fr;
    grid-area: container;
    align-items: baseline;
    padding: 30px 0;

    .user {
      grid-area: user;
      align-self: end;
      justify-self: start;
      display: flex;
      align-items: center;

      img {
        margin-right: 15px;
      }
    }
  }
}
