.user {
  display: flex;
  grid-area: user;
  justify-self: center;
  align-items: center;

  &__avatar {
    margin-right: 23px;
  }

  &__data {
    > * {
      margin: 0;
    }
  }
}
